<template>
    <section class="popup_box">
        <i-sticky>
            <i-header title="会议评分" @back-event="markBack" theme="light">
            </i-header>
            <div class="score_outer_box">
                <div class="score_box">
                    <div class="left">
                        <div>总分{{ totalPoints }}分</div>
                        <span>满分5分</span>
                    </div>
                    <div class="right">
                        <i-rate size="26px" :disabled="!isEdit" v-model="totalPoints"/>
                    </div>
                </div>
            </div>
        </i-sticky>
        <main
            class="mark_content"
            ref="wrapper"
            :style="{ height: wrapperHeight + 'px' }"
        >

            <!--细节评价-->
            <!--                <div class="commentBox">-->
            <div v-if="totalPoints < 4">
                <template v-for="(item, index) in commentList">
                    <div
                        :key="index"
                        class="row"
                        :class="{
                  borderBottom: item.hasBot,
                  marginBottom: !item.hasBot,
                }"
                        v-if="item.isShow"
                    >
                        <div class="row_left">{{ item.itemName }}</div>
                        <div class="row_right">
                            <template v-for="(childItem, i) in item.items">
                    <span
                        :class="{ isActive: childItem.isCheck }"
                        :key="'index' + i"
                        @click="
                        isEdit ? (childItem.isCheck = !childItem.isCheck) : ''
                      "
                    >{{ childItem.txt }}</span
                    >
                                <div
                                    :key="index + i"
                                    style="display: -webkit-box; width: 100%"
                                    v-if="(i + 1) % 3 === 0"
                                ></div>
                            </template>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="reason">
                <div class="title" :class="{ verify: totalPoints <= 3 }">原因</div>
                <textarea
                    placeholder="请输入反馈，总分1-3分请提供建议"
                    class="content"
                    :disabled="!isEdit"
                    v-model="reason"
                ></textarea>
            </div>
        </main>
        <footer class="footer_box" v-if="isEdit">
            <i-button type="primary" @click="onSubmit()">提交</i-button>
        </footer>
    </section>
</template>

<script>

export default {
    name: 'pfizerScore',
    data() {
        return {
            isEdit: true,
            wrapperHeight: 0,
            totalPoints: 0,
            commentList: [],
            reason: "",
            miceId: '',
        };
    },
    created() {
        this.miceId = this.$route.query.miceId;
        this.getScoreDict();
    },
    methods: {
        //提交
        onSubmit() {
            this.commentList.forEach((item) => {
                let desc = "";
                item.items.forEach((list) => {
                    if (list.isCheck) {
                        desc = `${desc}${list.txt};`;
                    }
                });
                item.desc = desc;
            });
            this.$idialog.confirm({
                title: '确认是否提交'
            }).then(res => {
                if (this.totalPoints <= 3 && !this.reason) {
                    this.$toast("总分小于等于3分时，原因必填");
                    return false;
                }
                const params = {
                    Total: this.totalPoints,
                    Descr: this.reason,
                    ExtData: JSON.stringify(this.commentList),
                    ProposalId: this.miceId,
                };
                this.$service.PfizerSaveScore(params).then((res) => {
                    if (res.success) {
                        this.$toast("评分成功");
                        this.getScoreDict();
                    }
                });
            })
            // this.$iMessage.show({
            //     contentTxt: "确认是否提交?",
            //     onConfirm: function () {
            //         if (this.totalPoints <= 3 && !this.reason) {
            //             this.$iToast("总分小于等于3分时，原因必填");
            //             return false;
            //         }
            //         let params = {
            //             Total: this.totalPoints,
            //             Descr: this.reason,
            //             ExtData: JSON.stringify(this.commentList),
            //             ProposalId: this.miceId,
            //         };
            //         this.$service.PfizerSaveScore(params).then((res) => {
            //             if (res.success) {
            //                 this.$iToast("评分成功");
            //                 this.getScoreDict();
            //             }
            //         });
            //     }.bind(this),
            //     onCancel: function (res) {
            //         console.log(res);
            //     },
            // });
        },
        //返回
        markBack() {
            this.$router.go(-1);
        },
        //设置滑动容器高度
        setHeight() {
            this.$nextTick(() => {
                let body_h = document.documentElement.clientHeight || window.innerHeight;
                // 常量
                const constant = this.isEdit ? 220 : 44;
                this.wrapperHeight = body_h - constant;
            });
        },
        // 获取试卷
        async getScoreDict() {
            this.isEdit = false;
            let params = {
                MiceId: this.miceId
            };
            let scoreHistory = await this.$service.PfizerGetScore(params);
            if (scoreHistory.content.length) {
                this.commentList = JSON.parse(scoreHistory.content[0].extData);
                this.totalPoints = scoreHistory.content[0].total;
                this.reason = scoreHistory.content[0].descr;
            } else {
                this.isEdit = true;
                let param = {
                    ProcessId: this.miceId
                };
                let scoreDict = await this.$service.PfizerGetScoreDict(param);
                this.getDataFormat(scoreDict.content.list);
            }
            console.log(this.isEdit);
            this.setHeight();
        },
        getDataFormat(list) {
            list.forEach((item) => {
                let ItemsLists = [];
                item.items.forEach((itemsList) => {
                    ItemsLists.push({
                        txt: itemsList,
                        isCheck: false,
                    });
                });
                item.items = ItemsLists;
            });
            this.commentList = list;
        },
    },
};
</script>

<style scoped="scoped" lang="less">
.popup_box {
    position: relative;

    .i-header {
        background-color: var(--themeColor);
        color: #fff;
    }

    .score_outer_box {
        height: 0.12rem;
        background-color: var(--themeColor);
        position: relative;
        padding: 0.2rem 0;

        .score_box {
            position: absolute;
            height: 0.64rem;
            width: calc(100% - 0.2rem);
            background-color: #fff;
            border-radius: 0.04rem;
            margin: 0 0.1rem;
            box-shadow: 0px 0.01rem 0.09rem 0px rgba(0, 0, 0, 0.15);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.16rem;
            box-sizing: border-box;

            .left {
                color: #13161B;

                div {
                    font-size: 0.16rem;
                    font-weight: bold;
                }

                span {
                    font-size: 0.12rem;
                    color: #909399;
                }
            }

            .right {
                /deep/ .van-rate--disabled {
                    .van-rate__icon--disabled {
                        color: var(--themeColor);
                    }
                }
            }
        }
    }


    .mark_content {
        margin-top: 0.4rem;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        color: #666666;
        padding: 0 0.2rem;
        padding-bottom: 0.2rem;
        -webkit-overflow-scrolling: touch;

        .row {
            width: 100%;
            background-color: white;
            padding: 0.1rem 0;

            .row_left {
                font-size: 0.15rem;
                font-weight: 600;
            }

            .row_right {
                display: block;

                span {
                    display: inline-block;
                    background-color: #f5f5f5;
                    color: #13161b;
                    width: 30.4%;
                    margin: 0.1rem 1%;
                    padding: 0.1rem 0;
                    border-radius: 0.04rem;
                    text-align: center;
                    box-sizing: border-box;
                }

                .isActive {
                    //@include backgroundColor();
                    background-color: var(--themeColor);
                    color: #fff;
                    box-shadow: 0px 2px 2px 0px var(--themeColor);
                }
            }
        }

        .borderBottom {
            border-bottom: 1px solid #eeeeee;
        }

        .marginBottom {
            margin-bottom: 0.1rem;
        }

        .reason {
            background-color: white;
            width: 100%;

            .title {
                height: 0.3rem;
                font-size: 0.15rem;
                font-weight: 600;
            }

            .verify:before {
                content: "*";
                color: red;
            }

            .content {
                width: calc(100% - 0.24rem);
                text-align: left;
                font-size: 0.12rem;
                color: #333;
                padding: 0.2rem 0.12rem;
                border: none;
                resize: none;
                border-color: transparent;
                outline: none;
                height: 1rem;
                background-color: #F5F5F5;
                border-radius: 0.04rem;
                //box-sizing: border-box;
            }

            /*改变textarea-placeholder颜色，变淡*/

            textarea::-webkit-input-placeholder {
                line-height: unset;
            }

            textarea:-moz-placeholder {
                line-height: unset;
            }

            textarea::-moz-placeholder {
                line-height: unset;
            }

            textarea:-ms-input-placeholder {
                line-height: unset;
            }
        }
    }

    .footer_box {
        background-color: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem 0;

        .van-button {
            width: calc(100% - 0.4rem)
        }
    }
}
</style>
